body::-webkit-scrollbar {
  width: 0;
}
body::-webkit-scrollbar-track {
  background: #1d2d5b;
}
body::-webkit-scrollbar-thumb {
  background-color: #2d3c69;
  border-radius: 2px;
}
